<template>
  <div>
    <!-- 创建B2B海运订单 -->
		<el-dialog :title="title" :lang="$i18n.locale" :visible.sync="popup" width="800px" append-to-body
			:close-on-click-modal="false" @close="handleDialogClose" v-loading="orderLoading">
			<el-form class="formList" ref="formList" :model="formList" :inline="true" :rules="rulesList"
				label-width="120px">
				<el-form-item :label="$t('commonInfo.clientOrderNoColumn')" prop="clientAwbNo">
					<el-input v-model="formList.clientAwbNo" clearable
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientOrderNoColumn')"
						@blur="onInputBlur" style="width:240px" />
				</el-form-item>
				<el-form-item :label="$t('commonInfo.packageAmount')" prop="packageAmount">
					<el-input v-model.number="formList.packageAmount" clearable
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageAmount')"
						style="width:240px" />
				</el-form-item>
				<el-form-item :label="$t('commonInfo.cnConsignorWhHouse')" prop="cnConsignorWh">
					<el-select v-model="formList.cnConsignorWh" clearable filterable default-first-option
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.cnConsignorWhHouse')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in consignorType" :key="item.dictValue" :label="item.dictLabel"
							:value="item.dictValue">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('B2BOrder.deType')" prop="destType">
					<el-select v-model="formList.destType" clearable filterable default-first-option
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('B2BOrder.deType')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in destTypeList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('B2BOrder.deCountry')" prop="destCountry">
					<el-select v-model="formList.destCountry"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('B2BOrder.deCountry')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in destCountryType" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 是否报关退税 -->
				<el-form-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')" prop="customsDeclarationFlag">
					<el-radio style="margin-right: 40px;" v-model="formList.customsDeclarationFlag" :label="true">
						{{$t('chinaWhB2BOrderPage.officialCD')}}</el-radio>
					<el-radio v-model="formList.customsDeclarationFlag" :label="false">
						{{$t('chinaWhB2BOrderPage.unofficialCD')}}</el-radio>
				</el-form-item>
				<!-- 是否含木含竹 -->
				<el-form-item :label="$t('chinaWhB2BOrderPage.containWoodOrBamboo')" prop="containWoodOrBambooFlag">
					<el-radio style="margin-right: 40px;" v-model="formList.containWoodOrBambooFlag" :label="1">
						{{$t('commonInfo.yesBtn')}}</el-radio>
					<el-radio v-model="formList.containWoodOrBambooFlag" :label="0">
						{{$t('commonInfo.noBtn')}}</el-radio>
				</el-form-item>
				<!-- 船类型 -->
				<!-- <el-form-item style="width: 100%;" :label="$t('chinaWhB2BOrderPage.shipType')" prop="shipType">
					<el-radio style="margin-right: 40px;" v-model="formList.shipType" :label="1">
						{{$t('chinaWhB2BOrderPage.normalShip')}}</el-radio>
					<el-radio v-model="formList.shipType" :label="2">
						{{$t('chinaWhB2BOrderPage.fastShip')}}</el-radio>
					<el-tooltip class="item" effect="dark"
						:content="$t('chinaWhB2BOrderPage.shipTypeTips')" placement="top-start">
						<i style="font-size: 22px; margin-left: 10px; color: #c7924c;" class="el-icon-warning"></i>
					</el-tooltip>
				</el-form-item> -->
			</el-form>
			<!-- 订单目的地信息 -->
			<el-collapse-transition>
				<div v-show="formList.destCountry != '' && formList.packageAmount != ''">
					<el-divider style="margin: 20px 0;" content-position="left">
						<h3>{{$t('chinaWhB2BOrderPage.seaOrderDestTitle')}}</h3>
					</el-divider>
					<el-row style="margin: 10px 0;">
						<el-button type="primary" icon="el-icon-plus" @click="handleAddSeaDest">
							{{$t('commonInfo.addBtn')}}
						</el-button>
						<el-button v-if="seaOrderDestList.length > 1" type="danger" icon="el-icon-delete"
							@click="handleClearSeaOrderDestInfo(isSa)">
							{{$t('commonInfo.deleteAllBtn')}}
						</el-button>
					</el-row>
					<el-table :data="seaOrderDestList" :loading="seaDestTableLoading" ref="seaDestTable" border
						style="width: 100%">
						<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="80">
							<template slot-scope="{row}">
								<span>{{optType == 'edit'?row.destType:returnDestType(row.destType)}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('commonInfo.deliveryAddress')" align="center" prop="destType"
							min-width="160">
							<template slot-scope="{row}">
								<!-- FBA FBN 显示仓库名称 OWH显示地址 -->
								<div v-if="row.destType == '5'">
									<span v-if="row.destWh == 'custom'">{{row.destWhAddress}}</span>
									<span v-else>{{row.destWh}}</span>
								</div>
								<div v-else><span>{{row.destWh}}</span></div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" align="center"
							min-width="80">
							<template slot-scope="{row}">
								<span>{{row.packageIndexString}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('chinaWhB2BOrderPage.cartonTotalPcs')" align="center"
							min-width="80">
							<template slot-scope="{row}">
								<span>{{row.pcsNum}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('commonInfo.operationColumn')" align="center" prop="destType"
							min-width="100">
							<template slot-scope="{row}">
								<el-button type="text" size="small" @click="handleUpdateDestInfo(row)"
									icon="el-icon-edit">{{$t('commonInfo.actionModify')}}</el-button>
								<el-button type="text" size="small" @click="handleDeleteDestInfo(row)"
									icon="el-icon-delete">{{$t('commonInfo.deleteBtn')}}</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-collapse-transition>
			<!-- 解析文件提示 -->
			<div class="skuFileTip">
				<h4><i class="el-icon-warning"></i>上传清单文件解析失败常见原因：</h4>
				<span>1、图片不要嵌入式，不能超出单元格，单元格只能有一张图片</span>
				<span>2、申报单价、申报总金额 不要为空，若不清楚则填写0</span>
				<span>3、检查是否有空白行以及特殊符号</span>
				<span>4、单箱产品数量、SKU和总数量不能为空，多个相同图片的SKU请按多行填写</span>
			</div>
			<div style="margin: 10px 0;height: 60px;">
				<SingleUpload @input="getSeaExcel" :tipText="$t('commonInfo.skuFileTips')" :preFile="fileTXTName"
					style="float: left;margin-left:22px;width:152px"></SingleUpload>
				<el-button size="small" style="float: left;margin-left:5px;" icon="el-icon-download"
					@click="$parent.handleDownloadTemplate">{{$t('commonInfo.downloadTemplate')}}</el-button>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleDialogClose" :disabled="submitLoading">
					{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" @click="submitForm" :disabled="submitLoading" :loading="submitLoading">
					{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 添加海运目的地信息窗口 -->
		<el-dialog :lang="$i18n.locale"  :title="seaDestTitle" :visible.sync="addSeaOrderDestPopup" width="600px" append-to-body
			:close-on-click-modal="false" @close="handleCloseSeaOrderDestDialog">
			<el-form ref="seaDestForm" :model="seaDestForm" :inline="true" :rules="seaDestRules" label-width="130px">
				<el-form-item :label="$t('chinaWhB2BOrderPage.destType')" prop="destType">
					<el-select v-model="seaDestForm.destType"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('chinaWhB2BOrderPage.destType')"
						style="width:400px">
						<el-option v-for="item in seaDestTypeList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="seaDestForm.destType != ''" :label="$t('commonInfo.deliveryWarehouse')"
					prop="destWh">
					<el-select v-model="seaDestForm.destWh" filterable
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')"
						style="width: 400px;">
						<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
							:value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<el-collapse-transition>
					<div v-show="showSeaDestInfo">
						<el-form-item :label="$t('commonInfo.deliveryDest')" prop="destWhAddress">
							<el-input style="width: 400px;" v-model="seaDestForm.destWhAddress"
								:disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.deliveryDest')">
							</el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.city')"
							prop="destCity">
							<el-select v-model="seaDestForm.destCity" :disabled="editSeaOrderCity"
								:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.city')"
								style="width: 400px;">
								<el-option v-for="item in cityList" :key="item.dictCode" :label="item.dictLabel"
									:value="item.dictLabel"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('commonInfo.fbaNo')" prop="fbaNo">
							<el-input v-model="seaDestForm.fbaNo" :placeholder="$t('commonInfo.FBATips')"
								style="width: 400px"></el-input>
						</el-form-item>
		
						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('commonInfo.fbaNoCode')"
							prop="fbaTrackingNo">
							<el-input v-model="seaDestForm.fbaTrackingNo" style="width: 400px"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNoCode')"></el-input>
						</el-form-item>
						<!-- 预约送仓时间 -->
						<el-form-item v-if="seaDestForm.destType == '2'" :label="$t('commonInfo.deliveryDate')"
							prop="reservationTime">
							<el-date-picker clearable style="width: 400px;" v-model="seaDestForm.reservationTime"
								:picker-options="deliveryPickerOptions" type="date" value-format="yyyy-MM-dd"
								:placeholder="$t('chinaWhB2BOrderPage.deliveryDateTips')"></el-date-picker>
						</el-form-item>
						<!-- 预约信息 注意：该字段与上传PDF文件绑定，填写或者上传某一个，则另一个必填-->
						<el-form-item v-if="seaDestForm.destType == '2'" :label="$t('commonInfo.asnNo')" prop="fbnNo">
							<el-input v-model="seaDestForm.fbnNo" style="width: 400px"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.asnNo')"></el-input>
						</el-form-item>
						<!-- 选择自定义仓库时 需要必填联系人和联系电话 -->
						<el-form-item :label="$t('commonInfo.contactMan')" v-if="seaDestForm.destType == '5'"
							prop="consignee">
							<el-input v-model="seaDestForm.consignee" style="width: 400px" :disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contactMan')"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.contact')" v-if="seaDestForm.destType == '5'"
							prop="contactTel">
							<el-input v-model="seaDestForm.contactTel" style="width: 400px" :disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contact')"></el-input>
						</el-form-item>
						<!-- 箱序号 -->
						<el-form-item :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" prop="packageIndexString">
							<el-input v-model="seaDestForm.packageIndexString" style="width: 400px" type="textarea"
								:placeholder="$t('chinaWhB2BOrderPage.inputCartonIndexTips')"></el-input>
						</el-form-item>
						<!-- 派送箱总PCS -FBA类型 -->
						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('chinaWhB2BOrderPage.cartonTotalPcs')" prop="pcsNum">
							<el-input v-model="seaDestForm.pcsNum" style="width: 400px" type="text"
								:placeholder="$t('chinaWhB2BOrderPage.cartonTotalPcsTips')"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.remark')" prop="remarks">
							<el-input v-model="seaDestForm.remarks" style="width: 400px" type="textarea"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.remark')"></el-input>
						</el-form-item>
					</div>
				</el-collapse-transition>
			</el-form>
			<div v-show="showSeaDestInfo && seaDestForm.destType != '5'" style="margin: 10px 0;height: 60px;">
				<SingleUploadPDF v-model="seaReservationFile" :tipText="$t('commonInfo.fbxFileTips')"
					:preFile="seaFbxPreName" @input="getSeaFBXInfo" class="fileUploadBtn">
				</SingleUploadPDF>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCloseSeaOrderDestDialog" :disabled="seaDestLoading">
					{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" :disabled="seaDestLoading" :loading="seaDestLoading"
					@click="submitSeaOrderDestInfo">
					{{seaDestLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 海运订单目的地信息详情 -->
		<el-dialog :lang="$i18n.locale"  :title="$t('chinaWhB2BOrderPage.seaOrderDestTitle')" :visible.sync="checkDestInfoPopup" width="800px"
			append-to-body :close-on-click-modal="false" @close="handleCloseCheckDestDialog">
			<el-row class="seaOrderDestDetail">
				<el-col :span="5"><label>{{$t('commonInfo.orderNoColumn') + ':'}}</label></el-col>
				<el-col :span="7"><span>{{checkDestInfoDetail.awbNo}}</span></el-col>
				<el-col :span="5"><label>{{$t('commonInfo.packageAmount') + ':'}}</label></el-col>
				<el-col :span="7"><span>{{checkDestInfoDetail.packageAmount}}</span></el-col>
				<el-col :span="5"><label>{{$t('commonInfo.cnConsignorWhHouse') + ':'}}</label></el-col>
				<el-col :span="7"><span>{{checkDestInfoDetail.cnConsignorWh}}</span></el-col>
				<el-col :span="5"><label>{{$t('B2BOrder.deCountry') + ':'}}</label></el-col>
				<el-col :span="7"><span>{{checkDestInfoDetail.destCountry}}</span></el-col>
			</el-row>
			<el-divider style="margin: 20px 0;" content-position="left">
				<h3>{{$t('chinaWhB2BOrderPage.seaOrderDestTitle')}}</h3>
			</el-divider>
			<!-- 到港后不能修改目的地信息 -->
			<el-row v-if="checkDestInfoDetail.trackingStatusValue < 320" style="margin: 10px 0;">
				<el-button type="primary" icon="el-icon-plus" @click="handleAddSeaDetailDest">
					{{$t('commonInfo.addBtn')}}
				</el-button>
				<el-button v-if="seaOrderDestList.length > 1" type="danger" icon="el-icon-delete"
					@click="handleClearSeaOrderDestInfo(isSa)">
					{{$t('commonInfo.deleteAllBtn')}}
				</el-button>
			</el-row>
			<el-table :data="seaOrderDestList" ref="seaDetailDestTable" border style="width: 100%">
				<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="80">
					<template slot-scope="{row}">
						<span>{{row.destType}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.deliveryAddress')" align="center" prop="destType"
					min-width="160">
					<template slot-scope="{row}">
						<!-- FBA FBN 显示仓库名称 OWH显示地址 -->
						<div v-if="row.destType == 'OWH'">
							<span v-if="row.destWh == 'custom'">{{row.destWhAddress}}</span>
							<span v-else>{{row.destWh}}</span>
						</div>
						<div v-else><span>{{row.destWh}}</span></div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" align="center" min-width="100">
					<template slot-scope="{row}">
						<span>{{row.packageIndexString}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('chinaWhB2BOrderPage.cartonTotalPcs')" align="center"
					min-width="80">
					<template slot-scope="{row}">
						<span>{{row.pcsNum}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.operationColumn')" align="center" prop="destType"
					min-width="100">
					<template slot-scope="{row}">
						<el-button v-if="checkDestInfoDetail.trackingStatusValue < 320" type="text" size="small"
							@click="handleUpdateDestInfo(row)" icon="el-icon-edit">{{$t('commonInfo.actionModify')}}
						</el-button>
						<el-button v-if="checkDestInfoDetail.trackingStatusValue < 320" type="text" size="small"
							@click="handleDeleteDestInfo(row)" icon="el-icon-delete">{{$t('commonInfo.deleteBtn')}}
						</el-button>
						<el-button v-if="checkDestInfoDetail.trackingStatusValue >= 320" type="text" size="small"
							@click="handleCheckDestInfo(row)" icon="el-icon-view">{{$t('commonInfo.actionDetail')}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCloseCheckDestDialog">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
			</div>
		</el-dialog>
		<!-- 订单目的地详情 -->
		<el-dialog :lang="$i18n.locale"  :title="$t('chinaWhB2BOrderPage.destInfo')" :visible.sync="destDetailPopup"
			:close-on-click-modal="false" width="600px" :append-to-body="true">
			<el-descriptions style="margin-bottom: 20px;" :column="1" border :labelStyle="{'width':'120px'}">
				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.destType')">{{destDetailForm.destType}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.deliveryWarehouse')">
					{{destDetailForm.destWh == 'custom'?"自定义海外仓":destDetailForm.destWh}}</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.deliveryDest')">{{destDetailForm.destWhAddress}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.city')">{{destDetailForm.destCity}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA'" :label="$t('commonInfo.fbaNo')">
					{{destDetailForm.fbaNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA'" :label="$t('commonInfo.fbaNoCode')">
					{{destDetailForm.fbaTrackingNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBN'" :label="$t('commonInfo.deliveryDate')">
					{{destDetailForm.reservationTime}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBN'" :label="$t('commonInfo.asnNo')">
					{{destDetailForm.fbnNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'OWH'" :label="$t('commonInfo.contactMan')">
					{{destDetailForm.consignee}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'OWH'" :label="$t('commonInfo.contact')">
					{{destDetailForm.contactTel}}</el-descriptions-item>
		
				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.seaCartonIndex')">
					{{destDetailForm.packageIndexString}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA' || destDetailForm.destType == 'FBN'"
					:label="$t('commonInfo.actionDownloadPDF')">
					<el-link type="primary" :href="destDetailForm.reservationFile" target="_blank">
						{{$t('commonInfo.actionDownload')}}</el-link>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.remark')">{{destDetailForm.remarks}}</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
  </div>

</template>

<script>
  import SingleUploadPDF from "@/components/upload/singleUploadPDF";
  import SingleUpload from "@/components/upload/singleUpload";
	import {
		validNumberToFix2,
		validPositiveInteger
	} from '@/utils/validate' //验证数字
  export default {
    name: 'CreateOrder',
    components: {
      SingleUpload,
			SingleUploadPDF
    },
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowData:{
        type:Object,
        default:function(){
        	return {};
        }
      },
      optType:{
        type:String,
        default:function(){
          return '';
        }
      }
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
      		
          if(newValue){
            if(this.optType == 'add'){
							this.popup = newValue;
              this.title = this.$t('commonInfo.createSeaOrder');
            }else if(this.optType == 'edit'){// 修改
						  this.popup = newValue;
              this.title = this.$t('commonInfo.modifySeaOrder');
							this.getSeaOrderDetail();
            }else if(this.optType == 'check'){// 查看目的地信息
							this.checkDestInfoPopup = true;
							this.getDestInfo();
						}
						this.getDict();
          }
      	},
      	immediate: true,
      },
			
			// 海运订单目的地信息创建监听
			'formList.destCountry': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						if (this.isSa == '') { // 第一次选择
							this.isSa = val;
						} else { // 更改国家提示清空目的地信息
							if (this.isSa != val) {
								if (this.seaOrderDestList.length > 0) {
									this.handleClearSeaOrderDestInfo(val);
								} else {
									this.isSa = val;
								}
							}
						}
					}
				}
			},
			"seaDestForm.destType": {
				immediate: true,
				handler(val) {
					this.showSeaDestInfo = false;
					this.seaDestForm.destWh = '';
					this.selectWhList = [];
					if (val != '' && val != null) {
						this.getWhDict(val);
					}
				}
			},
			'seaDestForm.destWh': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						this.getSeaOrderDestInfo(val);
						this.showSeaDestInfo = true;
					}
				}
			}

    },
    data() {
      return {
        popup:false,
        title:'',

        submitLoading:false,
				loading:false,
				orderLoading:false,
        
				formList: {
					clientAwbNo: '',
					packageAmount: '',
					cnConsignorWh: '',
					destType: '',
					destCountry: '',
					skuListLink: '',
					customsDeclarationFlag:false,//是否报关
					containWoodOrBambooFlag:0,//是否含木含竹 0否1是
					shipType:1,//船类型，1、普船，2、快船
				},
				consignorType: [],
				destTypeList: [{
						value: 'FBA',
						label: 'FBA',
					},
					{
						value: 'FBN',
						label: 'FBN',
					},
					{
						value: '其他海外仓/私人地址',
						label: '其他海外仓/私人地址',
					},
					{
						value: 'SKU上架',
						label: 'SKU上架',
					},
					{
						value: '整箱上架',
						label: '整箱上架',
					}
				],
				destCountryType: [{
						value: 1,
						label: 'Saudi Arabia(沙特)',
					},
					{
						value: 2,
						label: 'United Arab Emirates(阿联酋)',
					}
				],
				isSa: '', //是否选择沙特 1沙特 2阿联酋
				fileTip:'',
				tipInfo:'',
				selectWhList:[],
				cityList:[],
				// 沙特城市选项
				saCityList: [],
				// 阿联酋城市列表
				aeCityList:[],
				
				
				// 海运订单目的地信息
				seaOrderDestList: [],
				seaDestTableLoading: false,
				
				// 创建海运订单目的地信息
				seaDestTitle: '',
				editSeaDest: false,
				addSeaOrderDestPopup: false,
				
				// 预约送仓时间限制选择范围
				deliveryPickerOptions: { // 限制送仓时间的选择范围
					disabledDate(time) {
						let nowDate = Date.now();
						nowDate = nowDate - 24 * 60 * 60 * 1000; //可选当天
						let nowDay = new Date(time).getDay(); //周几
						// 超过当前时间的日期不可选
						return nowDate > time.getTime() ||
							nowDay == 5
					},
				},
				customerOrderNo:'',
				seaDestForm: {
					id: '', //ID
					orderId: '', //订单ID
					index: '', //标识
					destType: '', //FBA NOON OWH
					fbaNo: '', //FBA号
					fbaTrackingNo: '', //FBA追踪编码
					fbnNo: '', //ASN号
					reservationNumber: '', //预约号
					reservationFile: '', //入仓文件
					reservationTime: '', // 预约送仓文件
					destCountry: '', // 目的国家
					destCity: '', //目的城市
					destWh: '', //目的仓库
					destWhAddress: '', //目的送仓地址
					consignee: '', //联系人
					contactTel: '', //联系人电话
					remarks: '', //备注
					packageIndexString: '', //1-5,7,9-10,12
					pcsNum:'',// 当前目的地箱子总PCS数
					cartonIndexStr: '',
					seaOrderDestPackageInfoDto: { // 绑定箱信息
						orderType: '', //订单类型
						// 	packageId:'',//ID
						// 	packageAwbNo:'',//系统箱号
						// 	clientPackageAwbNo:'',//客户箱号
						packageIndex: '', //箱编号 创建订单前没有箱号需要用到
						// 	remarks:'',//备注
					},
				},
				seaDestLoading: false,
				seaReservationFile: '', //预约文件
				seaFbxPreName: [], // 上传文件预览
				seaDestTypeList: [{
						value: '1',
						label: 'FBA',
					},
					{
						value: '2',
						label: 'FBN',
					},
					{
						value: '5',
						label: 'OWH',
					}
				],
				showSeaDestInfo: false,
				editSeaOtherDest: false,
				editSeaOrderCity: false,
				
				filename: '', //SKU文件名
				fileTXTName: [], // 已上传文件集合，通过接口获取文件名和地址url，回传给子组件upload显示该订单已上传的文件列表
				
				
				// 查看目的地信息窗口
				checkDestInfoPopup: false,
				checkDestInfoDetail: {
					id: '',
					awbNo: '',
					destCountry: '',
					packageAmount: '',
					cnConsignorWh: '',
					trackingStatusValue: 0, // 订单状态
				},
				// 查看目的地详情窗口
				destDetailPopup: false,
				destDetailForm: {},
				
        
      }
    },
		//监听属性 类似于data概念
		computed: {
			rulesList() {
				//自定义验证规则 只能填写数字或者英文字母、-、_
				const validateCode = (rule, value, callback) => {
					let reg = /^[a-zA-Z0-9_-]+$/
					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error(this.$t('commonInfo.numberLimit')))
					}
				};
				// 只能填写数字
				const validateNum = (rule, value, callback) => {
					let reg = /^\+?[1-9][0-9]*$/
					if (!reg.test(value)) {
						callback(new Error(this.$t('commonInfo.positiveIntegerLimit')))
					} else {
						callback()
					}
				};
				return {
					clientAwbNo: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.clientOrderNoColumn'),
							trigger: 'blur'
						},
						{
							max: 20,
							message: this.$t('commonInfo.clientAwbNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					packageAmount: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.packageAmount'),
						trigger: 'blur'
					},{
						validator: validateNum,
						trigger: 'blur',
					}],
					cnConsignorWh: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.cnConsignorWhHouse'),
						trigger: 'blur'
					}],
					destCountry: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('B2BOrder.deCountry'),
						trigger: 'blur'
					}],
					customsDeclarationFlag: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.customsDeclaration'),
						trigger: 'blur'
					}],
					containWoodOrBambooFlag: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.containWoodOrBamboo'),
						trigger: 'blur'
					}],
				}
			},
			seaDestRules() {
				//自定义验证规则 只能填写数字或者英文字母
				const validateCode = (rule, value, callback) => {
					let reg = /[^\w\.\/]/ig
					if (reg.test(value)) {
						callback(new Error(this.$t('commonInfo.numberAndLetterLimit')))
					} else {
						callback()
					}
				};
				// 只能填写数字
				const validateNum = (rule, value, callback) => {
					let reg = /^\+?[1-9][0-9]*$/
					if (!reg.test(value)) {
						callback(new Error(this.$t('commonInfo.positiveIntegerLimit')))
					} else {
						callback()
					}
				};
				// 自定义验证箱序号规则，只能填写正数字和-和,  数字不能超过已填写总箱数，不能出现--和,,
				const validateCartonIndex = (rule, value, callback) => {
					let reg = /[^0-9,-]\d*$/g
					let reg2 = /--|-,|,,|,-/g
					if (reg.test(value) || reg2.test(value) || value.slice(0, 1) == '-' || value.slice(0, 1) == ',' ||
						value.slice(-1) == '-') {
						callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexTips')))
					} else {
						// 验证是否出现4-6-8 或者 8-6的情况
						let isErrorString = false, isErrorNumber = false;
						let firstArr = value.split(',');
						if(firstArr.length > 0){
							firstArr.forEach(item => {
								let secArr = item.split('-');
								if(secArr.length > 2){// 4-6-8多个-情况
									isErrorString = true;
								}else if(secArr.length == 2){
									if(parseInt(secArr[0]) > parseInt(secArr[1])){
										isErrorNumber = true;
									}
								}
							})
						}
						if(isErrorString){
							callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexTips')))
						}else if(isErrorNumber){
							callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexNumTips')))
						}else{
							callback()
						}
					}
				}
				return {
					destType: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('chinaWhB2BOrderPage.destType'),
						trigger: 'blur'
					}],
					destWh: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryWarehouse'),
						trigger: 'blur'
					}],
					destCity: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.city'),
						trigger: 'blur'
					}],
					destWhAddress: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDest'),
						trigger: 'blur'
					}],
					fbaNo: [{
							required: true,
							message: this.$t('commonInfo.FBAExample'),
							trigger: 'blur'
						},
						{
							min: 12,
							max: 12,
							message: this.$t('commonInfo.fbaNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					fbaTrackingNo: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.fbaNoCode'),
							trigger: 'blur'
						},
						{
							min: 8,
							max: 8,
							message: this.$t('commonInfo.fbaNoCodeLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
						 trigger: 'blur',
						}
					],
					fbnNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.asnNo'),
						trigger: 'blur'
					}, {
						validator: validateCode,
						trigger: 'blur',
					}],
					reservationTime: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.deliveryDateTips'),
						trigger: 'blur'
					}],
					consignee: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contactMan'),
						trigger: 'blur'
					}],
					contactTel: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contact'),
						trigger: 'blur'
					}],
					pcsNum: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('chinaWhB2BOrderPage.cartonTotalPcs'),
						trigger: 'blur'
					}, {
						validator: validateNum,
						trigger: 'blur',
					}],
					packageIndexString: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.inputCartonIndexTips'),
						trigger: 'blur'
					}, { // 正则，判断输入箱序号格式是否正确
						validator: validateCartonIndex,
						trigger: 'blur',
					}]
				}
			}
		},
    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
				this.formList = {
					clientAwbNo: '',
					packageAmount: '',
					cnConsignorWh: '',
					destType: '',
					destCountry: '',
					skuListLink: '',
					customsDeclarationFlag:false,//是否报关
					containWoodOrBambooFlag:0,//是否含木含竹 0否1是
					shipType:1,//船类型，1、普船，2、快船
				};
				this.isSa = '';
				this.seaOrderDestList = [];
				this.fileTXTName = [];
				
				
        this.$parent.rowData = {};
        this.$parent.optType = '';
        this.$parent.createSeaOrderPopup = false;
      },
			// 获取字典数据
			async getDict() {
				// 获取海运国内仓库地址
				const resWh = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_cn_consignor_wh'
					}
				});
				if (resWh.code === 200) {
					this.consignorType = resWh.rows;
				} else {
					this.$message.error(resWh.msg);
				}
				// 获取沙特城市地址 sys_b2bAirCity_list
				const cityRes = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_sea_destCity'
					}
				});
				if (cityRes.code === 200) {
					this.saCityList = cityRes.rows;
				} else {
					this.$message.error(cityRes.msg);
				}
				// 获取阿联酋 
				const city2Res = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_AE_cityList'
					}
				});
				if (city2Res.code === 200) {
					this.aeCityList = city2Res.rows;
				} else {
					this.$message.error(city2Res.msg);
				}
			
			},
			// 获取修改订单信息
			async getSeaOrderDetail(){
				this.customerOrderNo = this.rowData.clientAwbNo;
				const res = await this.$http.get("/toborder/sea/order/info/" + this.rowData.id);
				if (res.code === 200) {
					this.getSeaOrderDestData(this.rowData.id);
					this.formList = res.data;
					let str = this.formList.skuListLink.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: this.formList.skuListLink
					}]
					this.formList.containWoodOrBambooFlag === null?false:this.formList.containWoodOrBambooFlag;
				} else {
					this.$message.error(res.msg);
				}
			},
			// 获取目的地信息
			getDestInfo(){
				this.getSeaOrderDestData(this.rowData.id);
				this.isSa = this.rowData.destCountry == '沙特' ? '1' : '2';
				this.formList.destCountry = this.rowData.destCountry == '沙特' ? 1 : 2;
				this.formList.packageAmount = this.rowData.packageAmount;
				this.formList.awbNo = this.rowData.awbNo;
				this.formList.id = this.rowData.id;
				// 获取订单信息
				this.checkDestInfoDetail = {
					id: this.rowData.id,
					awbNo: this.rowData.awbNo,
					destCountry: this.rowData.destCountry,
					packageAmount: this.rowData.packageAmount,
					cnConsignorWh: this.rowData.cnConsignorWh,
					trackingStatusValue: this.rowData.trackingStatusValue,
				}
			},
			
			//客户订单号失去焦点事件
			async onInputBlur() {
				if (this.formList.clientAwbNo != "" && this.formList.clientAwbNo != null) {
					let reg = /^[a-zA-Z0-9_-]+$/
					if (reg.test(this.formList.clientAwbNo)) {
						if (this.optType == 'add') {
							const res = await this.$http.get(
								`/toborder/sea/order/verification?clientAwbNo=${this.formList.clientAwbNo}`);
							if (res.code == 200 && res.data == false) {
								this.$message.error(this.$t('B2BOrder.orderNoRepeat'));
							}
						}
			
						if (this.optType == 'edit' && this.formList.clientAwbNo != this.customerOrderNo) {
							const res = await this.$http.get(
								`/toborder/sea/order/verification?clientAwbNo=${this.formList.clientAwbNo}`);
							if (res.code == 200 && res.data == false) {
								this.$message.error(this.$t('B2BOrder.orderNoRepeat'));
							}
						}
					} else {
						this.$message.warning(this.$t('commonInfo.numberLimit'));
					}
				}
			},
			
			//获取仓库数据
			async getWhDict(type) {
				let dictText = '';
				switch (type) {
					case '1':
						dictText = this.isSa == '1' ? 'sys_SA_FBAWh' : 'sys_AE_FBAWh';
						this.fileTip = 'FBA外箱箱唛';
						this.tipInfo = '该订单只能对应一个FBA号，且下单前有明确的FBA号和FBA外箱箱唛则选择此种类型，该订单由我司预约派送时间';
						break;
					case '2':
						dictText = this.isSa == '1' ? 'sys_SA_NOONWh' : 'sys_AE_NOONWh';
						this.fileTip = 'NOON的送仓文件';
						this.tipInfo = '若该订单下单前明确获知ASN号且该订单只能对应一个ASN号，选择此类型';
						break;
					case '3':
						dictText = 'sys_SA_FleetanWh';
						this.tipInfo =
							'备注：1.若由于FBA库容不足导致下单前无法获悉明确的FBA号 2.NOON/SB2B下单前无法创建ASN号，但是能明确该订单是整个订单一次性派送，以上2种情况选择此种类型，免仓储期10个自然日（到达海外仓开始计算）。';
						break;
					case '4':
						dictText = 'sys_SA_FullfillmentWh';
						this.tipInfo = '若该订单需要用到我司海外仓上架履单，或者送NOON/SB2B的货物不是一整个订单一次派送则选择此种类型';
						break;
					case '5':
						dictText = this.isSa == '1' ? 'sys_SA_OtherWh' : 'sys_AE_OtherWh';
						this.tipInfo = '该订单只能对应一个送仓地址（其他海外仓或私人地址），选择此种类型，若该订单重量少于10KG，建议选择页面左侧B2C下单模式';
						break;
				}
				await this.$http.get("/toborder/system/dict/data/list?dictType=" + dictText).then(res => {
					if (res.code == 200) {
						this.selectWhList = res.rows;
						if (!this.isEdit) {
			
							// if (type == '3' || type == '4' || type == '5') { //海外仓直接默认第一项
							// 	this.form.destWhNo = this.selectWhList[0].dictValue
							// };
						}
					} else {}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			
			
			// 添加海运订单多目的信息
			handleAddSeaDest() {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = false;
				this.addSeaOrderDestPopup = true;
				this.seaDestForm.destCountry = this.formList.destCountry;
				this.cityList = this.formList.destCountry == 1?this.saCityList:this.aeCityList;
			},
			// 获取海运订单多目的地列表
			async getSeaOrderDestData(id) {
				let query = {
					orderId: id,
					orderType:'SEA',
					awbNo:this.checkDestInfoDetail.awbNo,
				}
				this.seaDestTableLoading = true;
				const res = await this.$http.post("toborder/sea/orderDest/list", query);
				if (res.code === 200) {
					this.seaOrderDestList = res.data;
					this.seaDestTableLoading = false;
				} else {
					this.seaDestTableLoading = false;
					this.$message.error(res.msg);
				}
			},
			
			// 返回订单服务类型
			returnDestType(type) {
				let text = '';
				switch (type) {
					case '1':
						text = 'FBA';
						break;
					case '2':
						text = 'FBN';
						break;
					case '5':
						text = 'OWH';
						break;
					case 'FBA':
						text = '1';
						break;
					case 'FBN':
						text = '2';
						break;
					case 'OWH':
						text = '5';
						break;
				}
				return text;
			},
			// 清空海运订单目的地信息
			handleClearSeaOrderDestInfo(val) {
				this.$confirm(this.$t('chinaWhB2BOrderPage.clearAllDestInfoTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						if (this.optType == 'edit' || this.optType == 'check') { // 已提交数据删除需要调接口
							let arr = [];
							this.seaOrderDestList.forEach(item => {
								arr.push({
									destType: item.destType,
									id: item.id,
								})
							})
							this.deleteSeaOrderDestInfo(arr);
						} else { // 本地删除
							this.seaOrderDestList = [];
							this.isSa = val;
						}
			
					})
					.catch(() => {
						this.formList.destCountry = this.isSa;
					})
			},
			// 根据目的地信息删除
			deleteSeaOrderDestInfo(arr) {
				this.$http.post("toborder/sea/orderDest/delete", arr).then(res => {
					if (res.code == 200) {
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(this.formList.id);
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.seaDestLoading = false;
				})
			},
			// 点击查看目的地详情
			handleCheckDestInfo(row) {
				this.destDetailPopup = true;
				this.destDetailForm = row;
			},
			// 上传文件
			getSeaExcel(data) {
				this.formList.skuListLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			
			// 点击更新目的地信息
			handleUpdateDestInfo(row) {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = true;
				this.getSeaDestInfo(row);
				this.addSeaOrderDestPopup = true;
			},
			// 获取目的地信息
			getSeaDestInfo(row) {
				let that = this;
				this.seaDestForm.destType = this.optType == 'edit' ||  this.optType == 'check'? this.returnDestType(row.destType) : row.destType;
				if (this.optType == 'edit' || this.optType == 'check') { // 修改则需要记录当前目的地信息
					this.seaDestForm.oldDestType = this.seaDestForm.destType;
				}
				setTimeout(function() {
					that.seaDestForm.destWh = row.destWh;
					that.showSeaDestInfo = true;
					setTimeout(function() {
						that.seaDestForm.id = row.id;
						that.seaDestForm.orderId = row.orderId;
						that.seaDestForm.index = row.index; //标识
						that.seaDestForm.fbaNo = row.fbaNo; //FBA号
						that.seaDestForm.fbaTrackingNo = row.fbaTrackingNo; //FBA追踪编码
						that.seaDestForm.fbnNo = row.fbnNo; //ASN号
						that.seaDestForm.reservationNumber = row.reservationNumber; //预约号
						that.seaDestForm.reservationFile = row.reservationFile; //入仓文件
						that.seaDestForm.reservationTime = row.reservationTime; // 预约时间
						that.seaDestForm.destCountry = row.destCountry; //目的国家
						that.seaDestForm.destCity = row.destCity; //目的城市
						that.seaDestForm.destWh = row.destWh; //目的仓库
						that.seaDestForm.destWhAddress = row.destWhAddress; //目的送仓地址
						that.seaDestForm.consignee = row.consignee; //联系人
						that.seaDestForm.contactTel = row.contactTel; //联系人电话
						that.seaDestForm.remarks = row.remarks; //备注
						that.seaDestForm.pcsNum = row.pcsNum;//箱总PCS数
						that.seaDestForm.packageIndexString = row.packageIndexString; //箱序号
						if (row.reservationFile != '' && row.reservationFile != null) {
							//修改upload的preview预览
							let str = row.reservationFile.split("~");
							that.seaFbxPreName = [{
								name: str[1],
								url: row.reservationFile,
							}]
						}
					}, 100)
				}, 100)
			},
			// 点击删除目的地信息
			handleDeleteDestInfo(row) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 修改订单删除目的地信息调后台接口，创建订单本地缓存增删
						if (this.optType == 'edit' || this.optType == 'check') {
							let query = [{
								destType: row.destType,
								id: row.id,
							}]
							this.deleteSeaOrderDestInfo(query);
						} else {
							let indexs = this.seaOrderDestList.findIndex(
								item => item.index === row.index
							);
							this.seaOrderDestList.splice(indexs, 1);
						}
					})
					.catch(() => {})
			},
			// 获取选中仓库信息
			getSeaOrderDestInfo(val) {
				let that = this;
				that.seaDestForm.destWhAddress = '';
				that.seaDestForm.consignee = '';
				that.seaDestForm.contactTel = '';
				that.seaDestForm.destCity = '';
				setTimeout(function() {
					that.selectWhList.forEach(item => {
						if (item.dictValue == val) {
							// 获取仓库的城市信息
							let whCity = '',whContact = '';
							if(item.remark && item.remark.includes('&&&')){
								whCity = item.remark.split('&&&')[1];
								whContact = item.remark.split('&&&')[0];
							}else{
								whCity = '';
								whContact = item.remark;
							}
							
							if (that.seaDestForm.destType == '5') { 
								//如果是海外仓，则获取字典存储的地址###联系人###联系电话信息
								// 2025-03-12 @zpy 字典备注更新：地址###联系人###联系电话&&&城市
								// 自定义海外仓可编辑，其他私人地址不可编辑
								if (val != 'custom') {
									if (item.remark != '' && item.remark != null) {
										let info = whContact.split('###');
										that.seaDestForm.destWhAddress = info[0];
										that.seaDestForm.consignee = info[1];
										that.seaDestForm.contactTel = info[2];
										if (that.seaDestForm.consignee == 'Fleetan-') {
											that.seaDestForm.consignee = that.seaDestForm.consignee + that
												.prefixAwbNo;
										}
										that.editSeaOtherDest = true; //不可编辑，直接填充
										that.editSeaOrderCity = true; // 编辑城市
			
										// 沙特海外仓仓库默认都在利雅得城市
										// if (that.isSa == 1) {
										// 	let list = that.cityList.filter(item => item.dictValue ==
										// 		'1');
										// 	that.seaDestForm.destCity = list[0].dictLabel;
										// } else { // 阿联酋默认迪拜
										// 	let list = that.cityList.filter(item => item.dictValue ==
										// 		'101');
										// 	that.seaDestForm.destCity = list[0].dictLabel;
										// }
										that.seaDestForm.destCity = whCity;
			
									}
								} else {
									// 自定义海外仓可以填写地点信息
									that.editSeaOtherDest = false;
									that.editSeaOrderCity = false;
								}
							} else { // FBA NOON
								that.editSeaOtherDest = true;
								that.editSeaOrderCity = true;
								that.seaDestForm.destWhAddress = whContact;
								// 沙特海外仓仓库默认都在利雅得城市
								// if (that.isSa == 1) {
								// 	let list = that.saCityList.filter(item => item.dictValue == '1');
								// 	that.seaDestForm.destCity = list[0].dictLabel;
								// } else { // 阿联酋暂时没有仓库，城市默认为空
								// 	let list = that.saCityList.filter(item => item.dictValue == '3');
								// 	that.seaDestForm.destCity = list[0].dictLabel;
								// }
								that.seaDestForm.destCity = whCity;
							}
						}
					})
				}, 300)
			},
			//添加目的地信息
			handleAddSeaDetailDest() {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = false;
				this.addSeaOrderDestPopup = true;
				this.formList.awbNo = this.checkDestInfoDetail.awbNo;
				this.formList.id = this.checkDestInfoDetail.id;
				this.formList.destCountry = 
				this.seaDestForm.destCountry = this.checkDestInfoDetail.destCountry == '沙特' ? '1' : '2';
				this.cityList = this.checkDestInfoDetail.destCountry == '沙特' ? this.saCityList:this.aeCityList;
				this.formList.packageAmount = this.checkDestInfoDetail.packageAmount;
			},
			// 关闭查看目的地信息窗口
			handleCloseCheckDestDialog() {
				this.seaOrderDestList = [];
				this.checkDestInfoDetail = {
					id: '',
					awbNo: '',
					destCountry: '',
					packageAmount: '',
					cnConsignorWh: '',
					trackingStatusValue: 0,
				}
				this.formList.packageAmount = '';
				this.formList.awbNo = '';
				this.checkDestInfoPopup = false;
				
				this.$parent.rowData = {};
				this.$parent.optType = '';
				this.$parent.createSeaOrderPopup = false;
			},
			// 获取上传文件
			getSeaFBXInfo(data) {
				this.seaDestForm.reservationFile = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.seaFbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			// 关闭添加海运订单目的地信息窗口
			handleCloseSeaOrderDestDialog() {
				this.seaDestForm = {
					id: '', //ID
					orderId: '', //订单ID
					index: '', //标识
					destType: '', //FBA NOON OWH
					fbaNo: '', //FBA号
					fbaTrackingNo: '', //FBA追踪编码
					fbnNo: '', //ASN号
					reservationNumber: '', //预约号
					reservationFile: '', //入仓文件
					reservationTime: '', // 预约送仓文件
					destCountry: '', // 目的国家
					destCity: '', //目的城市
					destWh: '', //目的仓库
					destWhAddress: '', //目的送仓地址
					consignee: '', //联系人
					contactTel: '', //联系人电话
					remarks: '', //备注
					packageIndexString: '',
					pcsNum:'',// 当前目的地箱子总PCS数
					cartonIndexStr: '',
					seaOrderDestPackageInfoDto: { // 绑定箱信息
						orderType: '', //订单类型
						// 	packageId:'',//ID
						// 	packageAwbNo:'',//系统箱号
						// 	clientPackageAwbNo:'',//客户箱号
						packageIndex: '', //箱编号 创建订单前没有箱号需要用到
						// 	remarks:'',//备注
					},
				}
				this.editSeaDest = false;
				this.seaReservationFile = ''; //预约文件
				this.seaFbxPreName = []; // 上传文件预览
				this.showSeaDestInfo = false;
				this.editSeaOtherDest = false;
				this.editSeaOrderCity = false;
				this.addSeaOrderDestPopup = false;
			},
			// 提交海运订单目的地信息
			submitSeaOrderDestInfo() {
				// FBA和FBN 需要上传送仓文件
				if (this.seaDestForm.destType == '1' || this.seaDestForm.destType == '2') {
					if (this.seaDestForm.reservationFile == '') {
						return this.$message.warning(this.$t('tipsInfo.submitOrderFBXFileTips'));
					}
				}
				console.log(this.seaDestForm);
				// 判断箱序号填写
				let strArr = this.seaDestForm.packageIndexString.split(',');
				let cartonStr = '',
					overMax = false;
				strArr.forEach(item => {
					if (item != '' && item != null) {
						if (item.indexOf('-') != -1) { // 范围
							for (var i = parseInt(item.split('-')[0]); i <= parseInt(item.split('-')[1]); i++) {
								cartonStr = cartonStr + i + ',';
								if (i > this.formList.packageAmount) {
									overMax = true;
								}
							}
						} else { // 单个箱序号
							cartonStr = cartonStr + item + ',';
							if (item > this.formList.packageAmount) {
								overMax = true;
							}
						}
					}
				})
				if (overMax) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.overCartonAmountTips'))
				}
				let reg = /,$/gi;
				cartonStr = cartonStr.replace(reg, '');
				this.seaDestForm.cartonIndexStr = cartonStr;
			
				this.$refs["seaDestForm"].validate(valid => {
					if (valid) {
						// 订单创建则本地缓存目的地信息，提交订单后一起提交目的地信息
						if (this.optType == 'add') {
							if (this.editSeaDest) { // 修改
								let index = this.seaOrderDestList.findIndex(item => item.index == this.seaDestForm
									.index);
								this.$set(this.seaOrderDestList, index, this.seaDestForm);
							} else { // 增加
								let nowTime = new Date().getTime();
								this.seaDestForm.index = nowTime;
								this.seaOrderDestList.push(this.seaDestForm);
							}
							this.handleCloseSeaOrderDestDialog();
						}
						// 修改订单则每次修改目的地信息都要请求后台接口
						else {
							if (this.editSeaDest) { // 修改
								this.updateSeaOrderDestInfo(this.seaDestForm);
							} else { // 增加
								this.createSeaOrderDestInfo(this.seaDestForm);
							}
						}
			
					}
				})
			},
			// 修改目的地信息
			updateSeaOrderDestInfo(form) {
				//新老目的地类型
				let obj = JSON.parse(JSON.stringify(form));
				obj.awbNo = this.formList.awbNo;
				obj.newDestType = this.returnDestType(obj.destType);
				obj.destType = this.returnDestType(obj.oldDestType);
				obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
				obj.seaOrderDestPackageInfoDto.orderType = 'SEA';
			
				this.seaDestLoading = true;
				this.$http.post("toborder/sea/orderDest/update", obj).then(res => {
					if (res.code == 200) {
						this.seaDestLoading = false;
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(obj.orderId);
						this.handleCloseSeaOrderDestDialog();
					} else {
						this.$message.error(res.msg);
						this.seaDestLoading = false;
					}
				}).catch(err => {
					this.seaDestLoading = false;
					this.$message.error(err.msg);
				})
			},
			// 增加目的地信息
			createSeaOrderDestInfo(form) {
				let obj = JSON.parse(JSON.stringify(form));
				obj.awbNo = this.formList.awbNo;
				obj.orderId = this.formList.id;
				obj.destType = this.returnDestType(obj.destType);
				obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
				obj.seaOrderDestPackageInfoDto.orderType = 'SEA';
				let arr = [];
				arr.push(obj);
				this.seaDestLoading = true;
				this.$http.post("toborder/sea/orderDest/create", arr).then(res => {
					if (res.code == 200) {
						this.seaDestLoading = false;
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(obj.orderId);
						this.handleCloseSeaOrderDestDialog();
					} else {
						this.$message.error(res.msg);
						this.seaDestLoading = false;
					}
				}).catch(err => {
					this.seaDestLoading = false;
					this.$message.error(err.msg);
				})
			},
			
			//提交海运订单
			submitForm() {
				if (this.formList.skuListLink == '') { //上传清单（必填）
					return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
				}
				if(this.formList.destType == '其他海外仓/私人地址'&&this.seaOrderDestList.length == 0){
					return this.$message.warning('其他海外仓/私人地址需要填写目的地信息');
				}
				// 提交订单需要判断目的信息是否有同个箱有多个派送地址
				// 修改订单不判断，目的地信息已单独调用后台接口
				if (this.optType == 'add') {
					let str = '';
					if (this.seaOrderDestList.length > 0) {
						this.seaOrderDestList.forEach(item => {
							str += item.cartonIndexStr + ','
						})
						let arr = str.split(',');
						let newArr = new Set(arr) // 去重
						if (arr.length != newArr.size) {
							return this.$message.warning(this.$t('chinaWhB2BOrderPage.repeatCartonTips'))
						}
					}
				}
				let data = {
					id: this.formList.id,
					clientAwbNo: this.formList.clientAwbNo,
					packageAmount: this.formList.packageAmount,
					cnConsignorWh: this.formList.cnConsignorWh,
					destType: this.formList.destType,
					destCountry: this.formList.destCountry,
					skuListLink: this.formList.skuListLink,
					customsDeclarationFlag:this.formList.customsDeclarationFlag,
					containWoodOrBambooFlag:this.formList.containWoodOrBambooFlag,
					shipType:this.formList.shipType,
				}
				this.$refs["formList"].validate(valid => {
					if (valid) {
						if (this.optType == 'edit') {
							this.submitLoading = true;
							this.$http.post("/toborder/sea/order/update", data).then(res => {
								if (res.code == 200) {
									this.submitLoading = false;
									this.$message.success(this.$t('B2BOrder.succeededOceanOrder'));
									this.handleDialogClose();
									this.$parent.getList();
								} else {
									this.$message.error(res.msg);
									this.submitLoading = false;
								}
							}).catch(err => {
								this.submitLoading = false;
								this.$message.error(err.msg);
							})
						} else {
							this.submitLoading = true;
							this.$http.post("/toborder/sea/order/create", this.formList).then(res => {
								if (res.code == 200) {
									if (this.seaOrderDestList.length > 0) {
										this.submitSeaDestData(res.data);
									} else {
										this.submitLoading = false;
										this.$message.success(this.$t('B2BOrder.addOceanOrder'));
										this.handleDialogClose();
										this.$parent.getList();
									}
								} else {
									this.$message.error(res.msg);
									this.submitLoading = false;
								}
							}).catch(err => {
								this.submitLoading = false;
								this.$message.error(err.msg);
							})
						}
			
					}
				})
			},
			// 提交海运订单目的地信息数据
			submitSeaDestData(data) {
				let arr = [];
				this.seaOrderDestList.forEach(item => {
					let obj = JSON.parse(JSON.stringify(item));
					switch (obj.destType) {
						case '1':
							obj.destType = 'FBA';
							break;
						case '2':
							obj.destType = 'FBN';
							break;
						case '5':
							obj.destType = 'OWH';
							break;
					}
					obj.awbNo = data.awbNo;
					obj.orderId = data.orderId;
					obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
					obj.seaOrderDestPackageInfoDto.orderType = 'SEA';
					arr.push(obj);
				})
				this.$http.post("/toborder/sea/orderDest/create", arr).then(res => {
					if (res.code == 200) {
						this.submitLoading = false;
						this.$message.success(this.$t('B2BOrder.succeededOceanOrder'));
						this.handleDialogClose();
						this.$parent.getList();
					} else {
						this.submitLoading = false;
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.submitLoading = false;
					this.$message.error(err.msg);
				})
			
			},
			
			
    },
    created() {
    },

  }
</script>

<style scoped>
	.seaOrderDestDetail {
		font-size: 18px;
		margin-bottom: 20px;
	}
	
	.seaOrderDestDetail .el-col {
		height: 40px;
		line-height: 40px;
	}
	
	.seaOrderDestDetail label {
		font-weight: bold;
	}
	
	.seaOrderDestDetail .el-col:nth-child(odd) {
		text-align: right;
	}
	
	.seaOrderDestDetail .el-col:nth-child(even) {
		text-indent: 20px;
	}
	
  .skuFileTip{
  	width: 690px;
  	margin-left: 20px;
  	margin: 10px 0;
  	background-color: #fffdd6;
  	border-radius: 4px;
  	padding: 5px 10px;
  }
  .skuFileTip h4{
  	margin: 0;
  	line-height: 30px;
  }
  .skuFileTip i{
  	margin: 0 5px;
  }
  .skuFileTip span{
  	display: block;
  	height: 20px;
  	line-height: 20px;
  	text-indent: 20px;
  }
  .skuFileTip label{
  	display: block;
  	height: 20px;
  	line-height: 20px;
  	text-indent: 40px;
  	font-weight: bold;
  }
  /deep/.parsingFilesFailedTips label{
  	display: block;
  }
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		.fileUploadBtn{
			float: right;
			margin: 0 10px;
			max-width: 220px;
		}
		.seaOrderDestDetail{
			.el-col {
				float: right;
			}
		}
	}
</style>
